<script>
  export let date;
  export let format = "long";
  export let inline = false;

  function parseDate(d) {
    const date = new Date(d);
    return [date.getDate(), date.getMonth() + 1];
  }

  function relativeDay(d, format = "long") {
    const date = new Date(d);
    const today = new Date();

    if (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth()
    ) {
      return "Oggi";
    }

    if (
      date.getDate() == today.getDate() + 1 &&
      date.getMonth() == today.getMonth()
    ) {
      return "Domani";
    }

    return Intl.DateTimeFormat("default", { weekday: format }).format(date);
  }

  const parsedDate = parseDate(date);
</script>

<h3 class:inline>{relativeDay(+date, format)}</h3>
<h2 class:inline>
  {parsedDate[0]}<span class="lighter">/{parsedDate[1]}</span>
</h2>

<style>
  h2 {
    text-align: center;
  }

  .lighter {
    font-size: 0.66em;
    letter-spacing: -0.2em;
  }

  .inline {
    display: inline-block;
    font-size: 1.2rem;
    font-family: "Oswald", sans-serif;
    letter-spacing: normal;
    text-align: start;
    font-weight: normal;
  }

  .inline .lighter {
    letter-spacing: normal;
    font-size: 00.8em;
  }

  h2.inline,
  h3.inline {
    text-transform: uppercase;
  }
</style>
