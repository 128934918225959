<script>
  import { fly } from "svelte/transition";
  import DateField from "./components/Date.svelte";
  import DateFilter from "./components/DateFilter.svelte";
  import NewMoviesAlert from "./components/NewMoviesAlert.svelte";

  async function getStuff() {
    const d = await fetch("movies");
    const tojson = await d.json();

    const test = [];

    tojson.DS.Scheduling.Events.forEach((e) => {
      test.push(new Movie(e));
    });
    return test;
  }

  class Movie {
    constructor(event) {
      this.id = event.TitleId;
      this.title = event.Title;
      this.picture = event.Picture;

      this.days = {};
      event.Days.forEach((day) => {
        const temp = [];
        day.Performances.forEach((p) => {
          temp.push(p.Time);
        });
        this.days[new Date(day.Day).getTime()] = temp;
      });
    }
  }

  function filterMoviesByDate(dates, movies) {
    // Return true when no filter is active :)
    if (dateFilter.length == 0) {
      return movies;
    }

    // Otherwise return an array of movies when date from filter is in movie.days
    return movies.filter((m) => dates.some((d) => m.days[d] != undefined));
  }

  function filterNewMovies(movies) {
    // Compare movies with those saved in localStorage
    // to check if new titles have been added
    const known = JSON.parse(localStorage.getItem("titles")) || [];
    const latest = [];
    movies.forEach((i) =>
      latest.push({ id: i.id, title: i.title, poster: i.picture })
    );
    // Avoid showing alerts when known list is empty (first visit)
    if (known.length == 0) {
      newMovies = [];
    } else {
      newMovies = latest.filter((i) => !known.some((k) => k.id == i.id));
    }
    localStorage.setItem("titles", JSON.stringify(latest));
  }

  let dateFilter = [];
  let newMovies = [];

  const movies = getStuff();

  movies.then((moviesArray) => filterNewMovies(moviesArray));
</script>

<main>
  {#await movies}
    <h1 style="text-align: center;">Caricamento</h1>
  {:then movies}
    <ul class="container">
      <DateFilter bind:filter={dateFilter} />
      {#each filterMoviesByDate(dateFilter, movies) as { id, title, days, picture }, index (id)}
        {@const splittedTitle = title.split(" - ")}
        <li
          class="card"
          {id}
          in:fly={{
            x: -200 * (index % 2 ? -1 : 1),
            duration: 500,
          }}>
          <div class="poster">
            <img
              src={`https://secure.webtic.it/angwt/${picture}`}
              alt=""
              srcset="" />
          </div>
          <div class="title">
            <h1>{splittedTitle[0]}</h1>
            {#if splittedTitle.length > 1}
              <h2>{splittedTitle.splice(1).join(" ")}</h2>
            {/if}
          </div>

          <div class="days_container">
            <ul class="days">
              {#each Object.keys(days) as day}
                {#if dateFilter.length == 0 || dateFilter.includes(+day)}
                  <li class="day">
                    <DateField date={+day} inline="true" />
                    <ul class="hours">
                      {#each days[day] as hour}
                        <li>{hour}</li>
                      {/each}
                    </ul>
                  </li>
                {/if}
              {/each}
            </ul>
          </div>
        </li>
      {/each}
    </ul>
  {:catch}
    <h1>Qualcosa è andato storto...</h1>
  {/await}

  {#if newMovies.length > 0}
    <NewMoviesAlert {newMovies} />
  {/if}
</main>

<style>
  .container {
    max-width: 40rem;
    margin: 1em auto;
  }

  @media screen and (max-width: 45rem) {
    .container {
      margin: 1em auto;
      padding: 0.5em;
    }
  }

  .days_container {
    grid-area: days;
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: black #ffdb20;
  }
  .days {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    flex-wrap: nowrap;
    list-style: none;
    user-select: none;
  }

  .days > :nth-child(odd) {
    background-color: #ffc40188;
  }

  .day {
    flex-shrink: 0;
    padding: 0.5em 1em;
  }

  .hours {
    margin-block: 0.5em;
    /* for the hours listing */
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding-inline-start: 1em;
    width: 80%;
    gap: 0.5em 1em;
  }
  .hours > li {
    list-style-type: none;
    font-family: Oswald, monospace;
    padding: 0 0.2em;
    background-color: black;
    color: white;
    border-radius: 5px;
  }

  .card {
    background-color: #ffdb20;
    background-image: linear-gradient(
      to bottom,
      rgb(255, 196, 0),
      transparent 40%
    );
    border: 12px solid black;
    overflow: hidden;
    display: grid;
    grid-template-columns: 34% 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "poster title"
      "poster days";
    margin: 1em 0;
  }

  .card:nth-of-type(even) {
    direction: rtl;
  }

  .card:nth-of-type(even) > * {
    direction: ltr;
    flex-direction: row-reverse;
  }

  .poster {
    grid-area: poster;
    z-index: 10;
  }

  .poster img {
    width: 100%;
    object-fit: cover;
    /*outline: 6px solid black;*/
    box-sizing: content-box;
    box-shadow: 0 0 20px black;
  }

  .title {
    padding: 1em 0.2em;
    align-self: center;
    grid-area: title;
    margin: 1em 0;
    text-align: center;
  }

  .title h2 {
    font-size: 1rem;
  }

  @media screen and (max-width: 550px) {
    .title {
      justify-self: center;
      text-align: center;
      margin: 0.5em 0;
    }

    .title h1 {
      font-size: 1.7em;
    }

    .title h2 {
      font-size: 0.88em;
    }

    .card {
      grid-template-areas:
        "title  title"
        "poster days";
    }

    .days {
      margin-top: 0.7em;
    }
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffdb20;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.281);
    border-radius: 1em;
  }
</style>
