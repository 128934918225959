<script>
  import DateField from "./Date.svelte";
  export let filter;

  const days = [];

  for (let i = 0; i < 7; i++) {
    const today = new Date();
    today.setDate(today.getDate() + i);
    today.setHours(0, 0, 0, 0);
    days.push(today);
  }

  function handleCheckbox(event) {
    event.target.checked == true
      ? (filter = [...filter, +event.target.value])
      : (filter = filter.filter((i) => i != +event.target.value));
  }
</script>

<div class="filter_box">
  <h4>Filtra per <strong>giorno</strong></h4>
  <form name="filter_box" on:change={(e) => handleCheckbox(e)}>
    <label for="reset">
      <span class="cross" class:showcross={filter.length != 0}>&cross;</span>
    </label>
    <input
      type="button"
      on:click={() => (filter = [])}
      disabled={filter.length == 0}
      id="reset" />
    {#each days as day, index}
      <input
        type="checkbox"
        id={index}
        value={day.getTime()}
        checked={filter.includes(day.getTime())} />
      <label for={index}><DateField date={day} format="short" /> </label>
    {/each}
  </form>
</div>

<style>
  .filter_box {
    position: sticky;
    top: 0;
    text-align: center;
    overflow: hidden;
    background-color: black;
    border-radius: 1em;
    z-index: 20;
  }
  .filter_box h4 {
    color: white;
    position: sticky;
    top: 0;
    font-size: 0.8rem;
  }

  .filter_box strong {
    color: gold;
  }

  form {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
  }

  input {
    display: none;
    width: 0;
    height: 0;
  }

  label {
    margin: 0;
    padding: 0.2em 0.5em;
    text-align: center;
    flex-grow: 1;
    background-color: black;
    color: white;
    font-size: 0.7rem;
  }

  input:checked + label {
    background-color: gold;
    color: black;
  }

  form :first-child {
    max-width: 4ch;
    text-align: right;
    overflow: hidden;
  }
  .cross {
    display: inline-block;
    color: gold;
    font-size: 1.5rem;
    font-weight: 900;
    transform: translateX(-1em) rotate(-360deg);
    opacity: 0;
    transition: all 0.6s;
  }

  .showcross {
    transform: none;
    opacity: 1;
  }

  @media screen and (max-width: 550px) {
    label {
      font-size: 0.5rem;
      align-self: stretch;
    }
  }
</style>
