<script>
  import { fade } from "svelte/transition";

  export let newMovies;

  let open = true;
</script>

{#if open}
  <div
    class="fullscreen"
    in:fade={{ delay: 800 }}
    out:fade
    on:click={() => (open = false)}>
    <div class="container">
      <div class="inner">
        <h1>
          {#if newMovies.length > 1}
            Sono stati aggiunti <br /><span>{newMovies.length}</span> nuovi film!
          {:else}
            È stato aggiunto <br /><span>1</span> nuovo film!
          {/if}
        </h1>
        <div class="list">
          {#each newMovies as movie}
            <a class="movie" href="#{movie.id}">
              <div class="poster">
                <img
                  src={`https://secure.webtic.it/angwt/${movie.poster}`}
                  alt="" />
              </div>
              <p>{movie.title}</p>
            </a>
          {/each}
          <div on:click={() => (open = false)} class="fico">Fico!</div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    z-index: 100;
  }

  .container {
    background-color: crimson;
    border-radius: 1rem;
    width: 34rem;
    padding: 1.7em 0.5em;
    text-align: center;
    max-height: 80%;
    display: flex;
    position: relative;
  }

  .inner {
    overflow-y: scroll;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: crimson;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(177, 16, 48);
  }

  ::-webkit-scrollbar-track {
    background: crimson;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1em;
    margin: 1em 0;
  }

  .movie {
    max-width: 25%;
    background-color: rgb(180, 16, 49);
    color: white;
    border-radius: 1em;
    overflow: hidden;
    padding-bottom: 0.3em;
    display: flex;
    flex-direction: column;
  }

  .poster {
    margin-bottom: 0.5em;
  }

  .movie p {
    margin: auto 0;
  }

  h1 span {
    color: white;
    font-family: Oswald, sans-serif;
  }

  .fico {
    background-color: gold;
    border-radius: 0.2em;
    padding: 0.5em 1em;
    position: absolute;
    bottom: -0.8em;
    z-index: 10;
    font-size: 1.5em;
    cursor: pointer;
  }

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }

  @media screen and (max-width: 550px) {
    .container {
      margin: 0.3em;
    }
    h1 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.6rem;
    }
  }
</style>
